import React from 'react';
import Menu from '../components/Menu';
export default class Header extends React.Component {
    render() {
        return (
            <>
            <div className="site-wrap" id="home-section">
                <div className="site-mobile-menu site-navbar-target">
                    <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close mt-3">
                        <span className="icon-close2 js-menu-toggle"></span>
                    </div>
                    </div>
                    <div className="site-mobile-menu-body"></div>
                </div>
                <header className="site-navbar site-navbar-target" role="banner" id="home">
                    <div className="container mb-3">
                        <div className="d-flex align-items-center">
                            <div className="site-logo mr-auto">
                            <a href="index.html">SEF<span className="text-primary">.</span></a>
                            </div>


                            <div className="top-social ml-auto">
                            <a href="https://www.facebook.com/SEFO-108605707547430/">
                              <span className="icon-facebook"></span>
                            </a>
                            <a href="#">
                              <span className="icon-twitter"></span>
                            </a>
                            <a href="#">
                              <span className="icon-linkedin"></span>
                            </a>
                          </div>

                        </div>
                    </div>
                    <Menu />
                </header>
            </div>
            </>
        )
    }
}
