import React from "react";

const SiteSectionPruebasPsi = () => {
  return (
    <div className="site-section section-3" data-stellar-background-ratio="0.5" style={{backgroundImage: 'url("images/psicometricas.jpg")'}}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-10 text-justify">
            <h2 className=" text-white section-heading text-center">
              Pruebas Psicométricas
            </h2>



            <p className="lead text-white" >
            Herramienta que evalúa y mide el perfil detallado y profundo de
            los profesionistas, que nos permiten conocer de manera cualitativa
            y objetiva el funcionamiento mental (cognitivo, emocional,
            personalidad, inteligencia, intereses, sintomatología, entre otros)
            con finalidad incrementar la productividad de tu empresa y contratando
            a aquellos que hacen match con el fit cultural de tu organización.
            </p>
<br/>

            <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="service-21">
                    <div className="service-21-contents">
                        <a>
                            <h3>Personalidad</h3>
                        </a>

                    </div>


                    <span className="service-21-icon">
                        <span className="icon-accessibility"></span>
                    </span>

                    </div>
                </div>



            <div className="col-lg-4 col-md-6 mb-4">
                <div className="service-21">
                <div className="service-21-contents">
                    <a>
                        <h3>Comportamiento</h3>
                    </a>

                </div>

                <span className="service-21-icon">
                    <span className="icon-user"></span>
                </span>

                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="service-21">
                <div className="service-21-contents">
                    <a>
                        <h3>Inteligencia</h3>
                    </a>

                </div>
                <span className="service-21-icon">
                    <span className="icon-info"></span>
                </span>

                </div>
            </div>

            <div className="col-lg-7 col-md-6 mb-4">
                <div className="service-21">
                <div className="service-21-contents">
                    <a>
                        <h3>Valores </h3>
                    </a>

                </div>
                <span className="service-21-icon">
                    <span className="icon-book"></span>
                </span>

                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="service-21">
                <div className="service-21-contents">
                    <a>
                    <h3>Rasgos psicopatológicos </h3>



                    </a>

                </div>
                <span className="service-21-icon">
                    <span className="icon-bars"></span>
                </span>

                </div>
            </div>





            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteSectionPruebasPsi;
