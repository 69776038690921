import React from 'react'
const SiteSectionCarousel = () => {
    return (
        <div className="site-section bg-light" id="QuienesSomos">
            <div className="container">
                <div className="row justify-content-center  mb-5">
                    <div className="col-md-7 text-center">
                        <h3 className="text-black section-heading text-center">NOSOTROS</h3>


                    </div>

                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="nonloop-block-13 owl-carousel">
                            <div className="news-1" style={{backgroundImage: 'url("images/quienes-somos.jpg")'}}>

<br/>
<br/>

                            <h3 className=" text-red section-heading2 text-center">¿QUIÉNES SOMOS?</h3>
                                <div className="text">

                                <p>Somos una Compañía especializada en Servicios Profesionales
                                y Consultoría en Recursos Humanos desarrollando estrategias con la
                                finalidad de garantizar la optimización, desarrollo y crecimiento
                                de las organizaciones.</p>

                                </div>
                            </div>

                            <div className="news-1" style={{backgroundImage: 'url("images/mision2.jpg")'}}>

                            <br/>
                            <br/>

                                <h3 className=" text-red section-heading2 text-center">MISIÓN</h3>

                                <div className="text">
      <br/>

                                <p className="mb-4">Brindar servicios y soluciones integrales, siempre actuales y eficaces en
                                Recursos Humanos que respondan a las necesidades particulares de nuestros
                                clientes en su interés por obtener resultados satisfactorios vinculados
                                con la personalidad estratégica del negocio.</p>

                                </div>
                            </div>

                            <div className="news-1" style={{backgroundImage: 'url("images/VALORES.jpg")'}}>

                            <br/>
                            <br/>

                                <h3 className=" text-red section-heading2 text-center">VALORES</h3>
                                <div className="text">
                                <p className="mb-4">- Lealtad - Integridad </p>

<p className="mb-4">- Compromiso - Adaptabilidad</p>
<p className="mb-4">- Pasión - Claridad</p>
<p className="mb-4">- Responsabilidad Social.</p>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            </div>
    );
}

export default SiteSectionCarousel;
