import React from 'react'

const TrainingGen = () => {
    return (<div className="site-section section-3" data-stellar-background-ratio="0.5" style={{backgroundImage: 'url("images/Tgen.jpg")'}}>
    <div className="container">
        <div className="row justify-content-center text-center">
        <div className="col-11 text-center mb-5">
            <h2 className="text-white section-heading primary-color-icon text-center">Training Generations  </h2>

            <p className="lead text-white text-justify">
            Estamos enfocados en mejorar la calidad personal y profesional,
            contribuyendo al equilibrio, educación y desarrollo integral,
            a traves de talleres y cursos con las mejores prácticas y
            técnicas actuales del mundo global.
            </p>


        </div>


        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">


                <div className="service-21">
                <div className="service-21-contents">
                    <a>
                        <h3>Áreas de desarrollo</h3>
                    </a>

                </div>


                <span className="service-21-icon">
                    <span className="icon-line-chart"></span>
                </span>

                </div>
            </div>



        <div className="col-lg-4 col-md-6 mb-4">
            <div className="service-21">
            <div className="service-21-contents">
                <a>
                    <h3>Humanidades</h3>
                </a>

            </div>

            <span className="service-21-icon">
                <span className="icon-group"></span>
            </span>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className="service-21">
            <div className="service-21-contents">
                <a>
                    <h3>Sociales</h3>
                </a>

            </div>
            <span className="service-21-icon">
                <span className="icon-sitemap"></span>
            </span>

            </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
            <div className="service-21">
            <div className="service-21-contents">
                <a>
                    <h3>Administración</h3>
                </a>

            </div>
            <span className="service-21-icon">
                <span className="icon-file"></span>
            </span>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className="service-21">
            <div className="service-21-contents">
                <a>
                <h3>Psicológicas</h3>
                </a>

            </div>
            <span className="service-21-icon">
                <span className="icon-cogs"></span>
            </span>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className="service-21">
            <div className="service-21-contents">
                <h3>Integraciones</h3>

            </div>
            <span className="service-21-icon">
                <span className="icon-play-circle"></span>
            </span>

            </div>
        </div>


        </div>
    </div>
</div>);
}

export default TrainingGen;

const styles={
    header:{
        fontSize:"30px"
    },
    parragraph:{
        margin:"0"
    }
};
