import React from "react";

const BienestarIntegral = () => {
  return (
    <div
      className="site-section section-3"
      data-stellar-background-ratio="0.5"
      id="BienestarIntegral"
      style={{ backgroundImage: 'url("images/360.jpg")', color: "white" }}
    >
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-10 text-justify">
            <h2 className="text-white section-heading primary-color-icon text-center">
            Modelo 360 de Bienestar Integral
            </h2>
            <p className= "lead text-white">
          A través de nuestro modelo 360 de bienestar integral colaboramos
          con las nuevas organizaciones a la prevención de riesgos y al mismo
          tiempo se promueve una cultura organizacional en donde se desarrollan
          valores como la integridad, el respeto, equidad, la responsabilidad,
          el trabajo en equipo, por mencionar algunos, creemos firmemente que
          el desarrollo y la promoción de estos valores contribuyen al
          establecimiento de nuevas percepciones y actitudes en el personal en
          busca del logro de objetivos y el establecimiento de relaciones
          interpersonales sanas y congruentes.
            </p>

<br />
<br />


            <p className= "lead text-white">-Psicología (Atención al personal, Salud Mental)</p>
            <p className= "lead text-white">-Valoración y medición de (Estrés y Ansiedad)</p>
            <p className= "lead text-white">-Life Balance (Cuidado y Balance Personal)</p>
            <p className= "lead text-white">
              -Formación (Talleres y Capacitación, Manejo de Estrés
              Comunicación)
            </p>
            <p className= "lead text-white">
              -Grupos de Entrenamiento (Habilidades para la Comunicación,
              Estilos de Liderazgo)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BienestarIntegral;
