import React from "react";
import SiteSectionCover from "../components/SiteSectionCover";
import SiteSectionGrid from "../components/SiteSectionGrid";
import SiteSectionCounter from "../components/SiteSectionCounter";
import SiteSectionCards from "../components/SiteSectionCards";
import Beneficios from '../components/Beneficios';
import ContactUs from "../components/ContactUs";
import SiteSectionCarousel from "../components/SiteSectionCarousel";
import ReactGA from "react-ga";
function changeColor(obj) {
           if (obj.style.color == 'black') {
               obj.style.color = 'orange';
           } else {
               obj.style.color = 'black';
           }
       }

class HomePage extends React.Component {


  componentDidMount(){
      ReactGA.initialize("UA-150643977-2");
      ReactGA.pageview("/homepage")
  }
  render() {
    return (
      <>
        <SiteSectionCover />
      <SiteSectionCarousel />
        <SiteSectionCounter />

        <SiteSectionGrid /> {/* Seccion de bullets de servicios */}
        <Beneficios />
        <ContactUs />

<li onmouseover="changeColor(this)" onmouseout="changeColor(this)"> Change text color.</li>


      </>
    );
  }
}

export default HomePage;
