import React from "react";
const CulturaOrg = () => {
  return (
    <div
      className="site-section section-3"
      data-stellar-background-ratio=".5" id="CulturaOrganizacional"
      style={{ backgroundImage: 'url("images/post_9.jpg")' }}
    >
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-10 text-justify mb-5 text-white">
            <h2 className="text-white section-heading primary-color-icon text-center">
              Cultura Organizacional
            </h2>
            <p className= "lead text-white">
              Cultura Organizacional tiene como objetivo evaluar procesos, áreas
              de las empresas, así como también permite conocer la perspectiva y
              medir el desempeño y competencias de cada colaborador a través de
              diferentes ángulos para aumentar la productividad y el logro de
              objetivos.
            </p>
            <p className="lead text-white">
              Proceso a través de un de abanico criterios de
              medición, seleccionadas según la necesidad del cliente y la
              cultura de la empresa, analizando aspectos individuales y
              grupales, mediante Entrevista Profunda, Assessment, Competencias.
            </p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CulturaOrg;
